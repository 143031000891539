import React from "react";
import {
  Card,
  CardMedia,
} from "@mui/material";


const Landing = () => {
  return (
    <>
      <Card sx={{ mt: 2 }}>
        <CardMedia
          component="img"
          image="/optical.jpg"
          alt="Optical panel"
          height="200px"
        />
      </Card>
    </>
  );
}

export default Landing;