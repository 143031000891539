const services = [
  {
    title: "Network monitoring",
    image: "/24346583018_b78c742564_3k.jpg",
    altText: "Rack servers",
  },
  {
    title: "Monthly consultation",
    image: "/piqsels.com-id-fphep-resized.jpg",
    altText: "Rack servers",
  },
  {
    title: "VPN",
    image: "/Rack_Servers_Fujitsu_Primergy_3.jpg",
    altText: "Rack servers",
  },
  {
    title: "Mikrotik network configuration",
    image: "/19174658945_03b07b265f_b.jpg",
    altText: "Rack servers",
  },
  {
    title: "OLT configuration",
    image: "/OLT_Alcatel_CityPlay_Amiens_(3).jpg",
    altText: "Rack servers",
  },
  {
    title: "Problem solving",
    image: "/piqsels.com-id-smdyx.jpg",
    altText: "Rack servers",
  }
]

export default services;