import React from "react";
import {
  Grid,
  Toolbar,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import "../i18n";
import ServiceCard from "./ServiceCard";
import services from "../services";
import SectionTitle from "./SectionTitle";


const Services = () => {

  const { t } = useTranslation();

  return (
    <>
      <Toolbar id="services" />
      <SectionTitle
        title={t('services'.charAt(0).toUpperCase() + t('services').slice(1))}
      />
      <Grid container spacing={3} mt={2}>
        {
          services.map((service, index) => (
            <Grid item xs={12} md={6} lg={4} key={index}>
              <ServiceCard
                title={t(service.title)}
                image={service.image}
                altText={t(service.altText)}
              />
            </Grid>
          ))
        }
      </Grid>
    </>
  );
}

export default Services;