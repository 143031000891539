import React from 'react';
import {
  Card,
  CardHeader,
  CardMedia,
} from "@mui/material";


const ServiceCard = ({ title, image, altText }) => {
  return (
    <Card >
      <CardMedia
        component="img"
        image={image}
        alt={altText}
        height="140px"
      />
      <CardHeader title={title}></CardHeader>
    </Card>
  );
}

export default ServiceCard;