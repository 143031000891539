import React from "react";
import Landing from "./Landing";
import Services from "./Services";
import Contact from "./Contact";
import { Toolbar, Container } from "@mui/material";

const Content = () => {
  return (
    <main>
      <Toolbar />
      <Container>
        <Landing />
        <Services />
        <Contact />
      </Container>
    </main>
  );
}

export default Content;