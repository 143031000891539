import React from "react";
import {
  Box,
  Typography,
  Button,
  Stack,
  useMediaQuery,
  Toolbar,
} from "@mui/material";
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TelegramIcon from '@mui/icons-material/Telegram';
import SectionTitle from "./SectionTitle";
import { useTranslation } from "react-i18next";
import "../i18n";


const Contact = () => {

  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <>
      <Toolbar id="contact" />
      <Box>
        <SectionTitle
          title={t('contact'.charAt(0).toUpperCase() + t('contact').slice(1))}
        />
        <Typography variant="body1" gutterBottom sx={{ mt: 2 }}>
          {t('contact_text')}
        </Typography>
        <Stack
          spacing={2}
          direction={isMobile ? "column" : "row"}
          mt={2}
        >
          <Button
            variant="outlined"
            href="tel:+5571999715858"
            startIcon={<CallIcon />}
          >
            {t('call')}
          </Button>
          <Button
            variant="outlined"
            href={`mailto:${t('contact_email')}`}
            startIcon={<EmailIcon />}
          >
            {t('contact_email')}
          </Button>
          <Button
            variant="outlined"
            href={`https://wa.me/message/HM5B6KYM3S3OI1`}
            target="_blank"
            startIcon={<WhatsAppIcon />}
          >
            {'WhatsApp'}
          </Button>
          <Button
            variant="outlined"
            href="https://t.me/l3route"
            target="_blank"
            startIcon={<TelegramIcon />}
          >
            {'l3route'}
          </Button>
        </Stack>
      </Box>
    </>
  )
}

export default Contact;