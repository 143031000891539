import React from "react";
import { Box } from "@mui/material";

const Footer = () => {
  return (
    <Box component="footer" mt={2} mb={2} sx={{ textAlign: "center" }}>
      L3 Route &#169; {new Date().getFullYear()}
    </Box>
  )
}

export default Footer;