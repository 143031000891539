import React from "react";

import {
  Typography,
} from "@mui/material";


const SectionTitle = ({ title }) => {
  return (
    <Typography
      variant="h4"
      gutterBottom
      mt={2}
      sx={{ textAlign: "center" }}
    >
      {title}
    </Typography>
  );
}

export default SectionTitle;