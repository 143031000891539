import React, { useState } from "react";
import logo from "../logo.svg";
import {
  AppBar,
  Toolbar,
  Container,
  Stack,
  Button,
  Drawer,
  useMediaQuery,
  Grid,
  Divider,
  List,
  ListItem,
  Typography,
  Box,
} from "@mui/material";
import TranslateIcon from '@mui/icons-material/Translate';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link as ScrollLink, animateScroll } from "react-scroll";
import { useTranslation } from "react-i18next";
import "../i18n"


const Header = () => {

  const { t, i18n } = useTranslation();

  const changeLanguage = () => {
    i18n.changeLanguage(i18n.language === "en" ? "pt-BR" : "en");
  };

  const isMobile = useMediaQuery("(max-width:600px)");

  const [drawerOpen, setDrawerOpen] = useState(false);

  const toogleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };


  return (
    <>
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={toogleDrawer}
      >
        <Toolbar>
          <Box display="flex" flexGrow={1} />
          <List>
            <ListItem button onClick={toogleDrawer}>
              <ArrowBackIcon />
            </ListItem>
          </List>
        </Toolbar>
        <Divider />
        <List>
          <ListItem button onClick={() => {
            animateScroll.scrollToTop();
            toogleDrawer();
          }}>
            <Typography variant="h6">{t('home').toUpperCase()}</Typography>
          </ListItem>
          <ListItem button onClick={() => {
            animateScroll.scrollTo(document.getElementById('services').offsetTop);
            toogleDrawer();
          }}>
            <Typography variant="h6">{t('services').toUpperCase()}</Typography>
          </ListItem>
          <ListItem button onClick={() => {
            animateScroll.scrollTo(document.getElementById('contact').offsetTop);
            toogleDrawer();
          }}>
            <Typography variant="h6">{t('contact').toLocaleUpperCase()}</Typography>
          </ListItem>
        </List>
      </Drawer>
      <AppBar sx={{ backgroundColor: "#000" }}>
        <Container disableGutters>
          <Toolbar disableGutters={isMobile}>
            {!isMobile ? (
              <>
                <img src={logo} height="32px" alt="logo" />
                <Container disableGutters>
                  <Stack direction="row-reverse">
                    <Button color="inherit" onClick={changeLanguage}>
                      <TranslateIcon />
                    </Button>
                    <ScrollLink to="contact" smooth={true} offset={-64}>
                      <Button color="inherit">{t('contact')}</Button>
                    </ScrollLink>
                    <ScrollLink to="services" smooth={true} offset={-64}>
                      <Button color="inherit">{t('services')}</Button>
                    </ScrollLink>
                    <Button color="inherit" onClick={animateScroll.scrollToTop}>{t('home')}</Button>
                  </Stack>
                </Container>
              </>
            ) :
              (
                <Container disableGutters>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <Stack direction="row">
                        <Button color="inherit" onClick={toogleDrawer}>
                          <MenuIcon />
                        </Button>
                      </Stack>
                    </Grid>
                    <Grid item xs={4} sx={{ textAlign: "center" }}>
                      <img src={logo} height="32px" alt="logo" />
                    </Grid>
                    <Grid item xs={4}>
                      <Stack direction="row-reverse">
                        <Button color="inherit" onClick={changeLanguage}>
                          <TranslateIcon />
                        </Button>
                      </Stack>
                    </Grid>
                  </Grid>
                </Container>
              )
            }
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
}

export default Header;