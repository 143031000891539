import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      "home": "home",
      "services": "services",
      "contact": "contact",
      "Network monitoring": "Network monitoring",
      "Monthly consultation": "Monthly consultation",
      "Mikrotik network configuration": "Mikrotik network configuration",
      "OLT configuration": "OLT configuration",
      "Problem solving": "Problem solving",
      "contact_text": "If you have any questions, please contact us.",
      "contact_email": "contact@l3route.com",
      "call": "Call us",
    }
  },
  "pt-BR": {
    translation: {
      "home": "início",
      "services": "serviços",
      "contact": "contato",
      "Network monitoring": "Monitoramento de rede",
      "Monthly consultation": "Consultoria mensal",
      "Mikrotik network configuration": "Configuração de rede Mikrotik",
      "OLT configuration": "Configuração de OLT",
      "Problem solving": "Resolução de problemas",
      "contact_text": "Se você tiver alguma dúvida, entre em contato conosco.",
      "contact_email": "contato@l3route.com",
      "call": "Ligue para nós",
    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;